import React, { useEffect, useContext } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"

import "./discover.scss"
import Map from "../Map"

// import Swiper core and required modules
import SliderNavigation from '../../components/SharedComponents/SliderNavigation'
import { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

import { createMarkup, string_translation } from "../../../utils"

export default function Discover(props) {
  const { data } = props
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  // const {
  //   locale, // ar
  // } = useContext(TranslateUrlsContext)

  const swiperRef = React.useRef(null)
  useEffect(() => {
    const script1 = document.createElement("script")
    const script2 = document.createElement("script")
    const script3 = document.createElement("script")
    const script4 = document.createElement("script")

    script1.src = "/js/image-map-pro.min.js"
    script2.src = "/js/image-map-pro.js"
    
    script3.src = locale === "en" ? "/js/mapping.js" : "/js/mappingAr.js"
    script4.src = "https://kit.fontawesome.com/7749c9f08a.js"
    script1.defer = true
    script2.defer = true
    script3.defer = true
    script4.defer = true

    document.body.appendChild(script1)
    document.body.appendChild(script2)
    document.body.appendChild(script3)
    document.body.appendChild(script4)
  }, [])

  const { stringTranslations } = useStaticQuery(graphql`
    query {
      stringTranslations {
        listing_en:stringTranslations(lang:"en" , word: "LISTING VIEW")
        listing_ar:stringTranslations(lang:"ar" , word: "LISTING VIEW")
        map_en:stringTranslations(lang:"en" , word: "MAP VIEW")
        map_ar:stringTranslations(lang:"ar" , word: "MAP VIEW")
        learn_more_en:stringTranslations(lang:"en" , word: "LEARN MORE ABOUT OUR 8 DISTRICTS")
        learn_more_ar:stringTranslations(lang:"ar" , word: "LEARN MORE ABOUT OUR 8 DISTRICTS")
      }
    }
  `)
  const shouldRenderArrows = data?.homepageDistricts.length > 1

  return (
    <>
      <section className="discover active">
        <div className="section-title">
          <div className="container">
            <div className="small-title">
              {data.homepageDistrictBlockSubtitle}
            </div>
            <div className="big-title h2">{data.homepageDistrictBlockTitle}</div>
            <div className="viewing">
              <div className="list-view">
                <div className="list-view-text h2 active">
                  <Link to="#">
                  {string_translation(stringTranslations, "map", locale)}
                  </Link>
                </div>
                <div className="map-view-text h2">
                  <Link to="#">
                  {string_translation(stringTranslations, "listing", locale)}
                  </Link>
                </div>
                <div className="list-view-img">
                  <div className="switch-wrapper active"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="list-wrapper active">
          <div className="col-group discover-inner-wrapper">
            <div className="col-dt-3 discover-first-col-parent" data-aos="fade-up" data-aos-duration="400"
              data-aos-easing="ease-in-sine">
              <div className="discover-first-col">
                <div
                  className="discover-first-col-text"
                  dangerouslySetInnerHTML={createMarkup(
                    data.homepageDistrictText
                  )}
                ></div>
                <div className="discover-first-col-learn">
                  <div className="learn-btn">
                    <Link to={data.homepageDistrictsLink.url}>
                      <img src="/images/discover/learn-btn.svg" alt="Learn More" />
                    </Link>
                  </div>
                  <div className="learn-text">
                    <Link to={data.homepageDistrictsLink.url}>
                      {string_translation(
                        stringTranslations,
                        "learn_more",
                        locale
                      )}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-dt-9 discover-list-items">
              <Swiper
                // install Swiper modules
                modules={[Navigation, Pagination]}
                pagination={{ clickable: true }}
                id="discover-Swiper"
                spaceBetween={24}
                slidesPerView={1.2}
                slidesPerGroup={1}
                grabCursor={"true"}
                ref={swiperRef}
                loop={true}
                data-aos="fade-left" data-aos-duration="800" data-aos-once="true"
                data-aos-easing="ease-in-sine"
                breakpoints={{
                  540: {
                    slidesPerView: 2,
                  },

                  1200: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                  },
                }}
                dir={locale === "en" ? "ltr" : "rtl"}
              >
                {data?.homepageDistricts.map((item, index) => (
                  <SwiperSlide key={index} >
                    <div className="discover-list-item">
                      <div
                        className={
                          "discover-list-item-title-wrapper " +
                          item.districtOptions.color
                        }
                      >
                        <div
                          className="discover-list-item-title"
                          dangerouslySetInnerHTML={createMarkup(item.title)}
                        ></div>
                      </div>
                      <div className="discover-list-item-img">
                        <img data-aos="zoom-in" data-aos-duration="1000" data-aos-easing="ease-in-sine"
                          data-aos-delay="200"
                          src={item?.featuredImage?.node?.localFile?.publicURL}
                          alt={item?.title}
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
                {shouldRenderArrows && <SliderNavigation ref={swiperRef} />}
              </Swiper>
            </div>
          </div>
        </div>
        <div className="map-wrapper">
          <div className="container">
            <Map />
          </div>
        </div>
      </section>
    </>
  )
}
