import React,{useState, Component} from "react"
import ReactModal from 'react-modal'
import { Link } from 'gatsby'

import "./firsthomepage.scss"
import { titleDesign } from "../../../utils"
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Autoplay, Lazy } from "swiper"
import { embed_id } from "../../../utils"

import SliderNavigation from '../../components/SharedComponents/SliderNavigation'
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"



export default function FirstHomepage(props) {
  SwiperCore.use([Autoplay, Lazy])

  const { data, slider } = props
  const swiperRef = React.useRef(null)

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  const shouldRenderArrows = slider.length > 1

  const [isOpen, setOpen] = useState(false)

  const handleModalOpen = event => {

    setOpen(true)
  }

  const handleModalClose = event => {
    setOpen(false)
  }

  ReactModal.setAppElement('#___gatsby')
  let showModal = true
  // let showModal = true
  // if ( typeof document !== 'undefined' ) {
  //   showModal = true
  // }

  return (
    <>
      <section className="first-block">
        <div className="col-group wrapper">
          <div className="col-dt-7 img-wrapper">
            <video autoPlay playsInline muted loop>
              <source
                src={data?.homepageVideo?.localFile?.publicURL}
                type="video/mp4"
              />
            </video>
            {showModal && data?.videoLink ? (
              <div>
                <ReactModal
                  isOpen={isOpen}
                  onRequestClose={handleModalClose}
                 
                >
                  <div className="modal-video-movie-wrap">
                    <button class="modal-video-close-btn"  onClick={handleModalClose}></button>
                    <iframe width="460" height="230" src={"https://www.youtube.com/embed/"+data?.videoLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                </ReactModal>
                <div className="play-btn" onClick={() => setOpen(true)}>
                  <img src="/images/play-btn.svg" alt="Play" />
                </div>
                <div className="spinner">
                </div>

              </div>
            ) : (
              <div></div>
            )}
          </div>

          <div className="col-dt-5 lifestyle">
            <Swiper
              // install Swiper modules
              modules={[Navigation, Pagination]}
              pagination={{ clickable: true }}
              id="lifestyle-Swiper"
              className="swipers-components"
              spaceBetween={5}
              slidesPerView={1}
              slidesPerGroup={1}
              grabCursor={"true"}
              ref={swiperRef}
              loop={true}
              dir={locale === "en" ? "ltr" : "rtl"}
              autoplay={{
                delay: 4000,
                disableOnInteraction: true,
              }}
            >
              {slider?.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="lifestyle-wrapper">
                    <div
                      className="lifestyle-title h2"
                      dangerouslySetInnerHTML={titleDesign(item.sliderTitle)}
                    ></div>
                  </div>
                </SwiperSlide>
              ))}
              {shouldRenderArrows && <SliderNavigation ref={swiperRef} />}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  )
}
