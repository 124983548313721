import React, { useEffect, useContext } from "react"
import { Link } from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"

// import Swiper core and required modules
import { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import SliderNavigation from '../../components/SharedComponents/SliderNavigation'

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import { useStaticQuery, graphql } from "gatsby"
import { createMarkup, string_translation } from "../../../utils"

export default function SpotlightSwiper() {
  const dbdata = useStaticQuery(graphql`{
     news_ar:allWpPost(
      filter: {categories: {nodes: {elemMatch: {slug: {eq: "news"}}}}, language: {slug: {eq: "ar"}}}
      limit: 6
      sort: {fields: databaseId, order: DESC}
    ) {
        nodes {
          id
          title
          tags {
            nodes {
              name
            }
          }
          excerpt
          date(formatString: "DD-MM-YYYY")
          previewImage {
            previewImage {
              altText
              localFile {
                publicURL
              }
            }
          }
          uri
        }
    }
    news_en:allWpPost(
      filter: {categories: {nodes: {elemMatch: {slug: {eq: "news"}}}}, language: {slug: {eq: "en"}}}
      limit: 6
      sort: {fields: databaseId, order: DESC}
    ) {
        nodes {
          id
          title
          tags {
            nodes {
              name
            }
          }
          excerpt
          date(formatString: "DD-MM-YYYY")
          previewImage {
            previewImage {
              altText
              localFile {
                publicURL
              }
            }
          }
          uri
        }
    }
  }
  `)

  const {
    locale, 
    originalUrl
  } = useContext(TranslateUrlsContext)
  let news 
  if(locale == "ar") {
    news = dbdata.news_ar.nodes
  } else {
    news = dbdata.news_en.nodes
  }
  const shouldRenderArrows = news.length > 1

  const swiperRef = React.useRef(null)
  return (
    <div className="spotlight-wrapper">
      <div className="container">
        <Swiper
          modules={[Navigation, Pagination]}
          pagination={{ clickable: true }}
          id="spotlight-Swiper"
          spaceBetween={24}
          slidesPerView={1}
          slidesPerGroup={1}
          grabCursor={"true"}
          ref={swiperRef}
          breakpoints={{
            540: {
              slidesPerView: 2,
            },
            1200: {
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
          }}
          dir={locale === "en" ? "ltr" : "rtl"}
        >
          {news.map((item, i) => (
            <SwiperSlide key={"sl" + i}>
              <Link to={item.uri}  state={{ parenturl: originalUrl }}>
              <div
                className="spotlight-title h2"
                data-aos="fade-up"
                data-aos-duration="400"
                data-aos-easing="ease-in-sine"
              >
                {item.title}
              </div>
              <div
                className="date-wrapper"
                data-aos="fade-up"
                data-aos-duration="400"
                data-aos-easing="ease-in-sine"
              >
                <div className="date-tag">
                  {item.tags.nodes.map(n => n.name)}
                </div>
                <hr className="date-hr" />
                <div className="date">{item.date}</div>
              </div>
             
              <div
                className="spotlight-img"
                data-aos="fade-up"
                data-aos-duration="400"
                data-aos-easing="ease-in-sine"
                data-aos-delay="400"
              >
                <img
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-sine"
                  data-aos-delay="400"
                  src={item?.previewImage?.previewImage?.localFile?.publicURL}
                  alt={item?.previewImage?.previewImage?.altText || item?.excerpt.substring(0, 147).replace(/<[^>]*>?/gm, '')}
                />
              </div>
              </Link>
            </SwiperSlide>
          ))}
          <div className="mobile-show">
          {shouldRenderArrows && <SliderNavigation ref={swiperRef} />}
          </div>
        </Swiper>
      </div>
    </div>
  )
}
