import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import FirstHomepage from "../components/FirstHomepage"
import ProjectInNumber from "../components/ProjectInNumber"
import Twitter from "../components/Twitter"
import Discover from "../components/Discover"
import Spotlights from "../components/Spotlights"
import SliderPath from "../components/SliderPath"
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"

export default function Homepage(pageData) {
  const data = pageData?.data
  
  // const {
  //   locale, // ar
  // } = useContext(TranslateUrlsContext)
  let seo = pageData?.pageContext?.node?.seo
  
  return (
    <>
    <Seo data={seo}/>
    <Layout>
      <FirstHomepage data={data.wpPage.homepageFields.homepageVideo} slider={data.wpPage.homepageFields.homepageSlider}/>
      <ProjectInNumber
            data={data.wpPage.homepageFields.projectInNumber}
          />
      <SliderPath />
      <Discover data={data.wpPage.homepageFields}/>
      <Spotlights />
      <Twitter />
    </Layout>
    </>
  )
}

export const pageQuery = (graphql` query HomepageData($databaseId:Int) {
  wpPage(databaseId: {eq: $databaseId}) {
    id
      title
      content
      pageDescription {
        pageDescription
      }
      homepageFields {
        homepageDistricts {
          ... on WpDistrict {
            id
            content
            title
            featuredImage {
              node {
                localFile {
                  publicURL
                }
              }
            }
            districtOptions {
              color
            }
          }
        }
        homepageSlider {
          sliderTitle
        }
        homepageVideo {
          homepageVideo {
          localFile {
            publicURL
            }
          }
          videoLink
        }
        projectInNumber {
          desktopImage {
            localFile {
              publicURL
            }
          }
          mobileImage {
            localFile {
              publicURL
            }
          }
          text
        }
        homepageDistrictText
        homepageDistrictsLink {
          url
        }
				homepageDistrictBlockTitle
        homepageDistrictBlockSubtitle
      }
    }
}
`)
