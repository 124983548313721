import React from "react"
import "./sliderPath.scss"

// import Swiper core and required modules

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from "swiper"

// Import Swiper styles
import "swiper/css"

import "swiper/css/scrollbar"
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import {createMarkup } from '../../../utils'
import { useStaticQuery, graphql } from "gatsby"

export default function SliderPath(props) {
  SwiperCore.use([Autoplay])
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  
  const dbdata = useStaticQuery(graphql`
    query {
      
      belt_en:wpPage(template: {templateName: {eq: "Homepage"}}, language: {slug: {eq: "en"}}) {
        homepageFields {
          homepageBelt {
            beltText
            beltImage {
              localFile {
                publicURL
              }
            }
          }
        }
        template {
          templateName
        }
      }
      belt_ar:wpPage(template: {templateName: {eq: "Homepage"}}, language: {slug: {eq: "ar"}}) {
        homepageFields {
          homepageBelt {
            beltText
            beltImage {
              localFile {
                publicURL
              }
            }
          }
        }
        template {
          templateName
        }
      }
    }
  `)


  let data 
  if(locale === "ar") {
    data = dbdata.belt_ar.homepageFields.homepageBelt
  } else {
    data = dbdata.belt_en.homepageFields.homepageBelt
  }


  return (
    <>
    {data?<section className="path-slider">
        <Swiper
          id="path-Swiper"
          spaceBetween={150}
          slidesPerView={"auto"}
          slidesPerGroup={"1"}
          grabCursor={"true"}
          freeMode={"true"}
          loop={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            300: {
              slidesPerView: 1.3,
              slidesPerGroup: 1,
              centeredSlides: true,
            },
            540: {
              slidesPerView: "auto",
              slidesPerGroup: 1,
              centeredSlides: true,
            },
            1200: {
              slidesPerView: "auto",
              slidesPerGroup: 1,
            },
          }}
          dir={locale === "en" ? "ltr" : "rtl"}
        >
          <div slot="container-end" className="border-line"></div>
          {data?.map((belt, index) => (
            <SwiperSlide className="path-swiper-slide" key={index}>
              <div className="path-swiper-slide-img">
                <img src={belt?.beltImage?.localFile?.publicURL} alt={belt?.beltText} />
              </div>
              <div className="text-inner h2" dangerouslySetInnerHTML={createMarkup(belt?.beltText)}>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>:<div></div>}
      
    </>
  )
}
