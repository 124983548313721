import React from "react"
import "./twitter.scss"
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react"
import { useContext } from "react"
import { string_translation } from "../../../utils"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import { useStaticQuery, graphql, Link } from "gatsby"

// Import Swiper styles
import "swiper/css"
import "swiper/css/scrollbar"

export default function Twitter() {
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  const dbdata = useStaticQuery(graphql`
    query {
      stringTranslations {
        title_ar: translateString(language: AR, string: "TWITTER TITLE")
        title_en: translateString(language: EN, string: "TWITTER TITLE")
        subtitle_ar: translateString(language: AR, string: "TWITTER SUBTITLE")
        subtitle_en: translateString(language: EN, string: "TWITTER SUBTITLE")
      }

      twitter_en:wpPage(template: {templateName: {eq: "Homepage"}}, language: {slug: {eq: "en"}}) {
        homepageFields {
          homepageTwitter {
            twitterText
            twitterTitle
            twitterImage {
              localFile {
                publicURL
              }
            }
            twitterLink
            youtubeLink
            linkedinLink
            instagramLink
            facebookLink
          }
        }
        template {
          templateName
        }
      }
      twitter_ar:wpPage(template: {templateName: {eq: "Homepage"}}, language: {slug: {eq: "ar"}}) {
        homepageFields {
          homepageTwitter {
            twitterText
            twitterTitle
            twitterImage {
              localFile {
                publicURL
              }
            }
            twitterLink
            youtubeLink
            linkedinLink
            instagramLink
            facebookLink
          }
        }
        template {
          templateName
        }
      }
    }
  `)


  let data 
  if(locale === "ar") {
    data = dbdata.twitter_ar.homepageFields.homepageTwitter
  } else {
    data = dbdata.twitter_en.homepageFields.homepageTwitter
  }


  return (
    <>
      {data === undefined ? (
        ""
      ) : (
        <section className="twitter">
          <div className="section-title">
            <div className="container">
              <div className="small-title">
                {string_translation(
                  dbdata.stringTranslations,
                  "subtitle",
                  locale
                )}
              </div>
              <div className="big-title h2">
                {string_translation(dbdata.stringTranslations, "title", locale)}
              </div>
            </div>
          </div>

          <div className="twitter-wrapper">
            <div className="container">
              <Swiper
                id="twitter-Swiper"
                spaceBetween={24}
                slidesPerView={1.2}
                slidesPerGroup={1}
                freeMode={"true"}
                grabCursor={"true"}
                breakpoints={{
                  300: {
                    slidesPerView: "auto",
                    slidesPerGroup: 1,
                    freeMode: true

                  },
                  1400: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                  },
                }}
                dir={locale === "en" ? "ltr" : "rtl"}
              >
                {data?.map((tweet, index) => (
                  <SwiperSlide className="swiper-slide" key={"tweet-" + index}>
                    <div className="twitter-inner-wrapper">
                      <div
                        data-aos="fade-up"
                        data-aos-duration="400"
                        data-aos-easing="ease-in-sine"
                        className="twitter-item-img"
                      >
                        <Link
                          to={tweet.twitterLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            data-aos="zoom-in"
                            data-aos-duration="400"
                            data-aos-easing="ease-in-sine"
                            data-aos-delay="100"
                            src={tweet?.twitterImage?.localFile?.publicURL}
                            alt={tweet?.twitterText?.substring(0, 90).replace(/<[^>]*>?/gm, '')}
                          />
                        </Link>
                      </div>
                      <div
                        data-aos="fade-up"
                        data-aos-duration="400"
                        data-aos-easing="ease-in-sine"
                        data-aos-delay="200"
                        className="twitter-user"
                      >
                        <div className="twitter-user-text">
                          {tweet?.twitterTitle}
                        </div>
                        <div className="twitter-logo">
                        {tweet.twitterLink != null ? (
                        <Link
                          to={tweet.twitterLink} > <img className={tweet.twitterLink == null ? `linkhide` : `linkshow`} src="/images/twitter/x_logo.svg" alt="X" />
                        </Link>
                        ) : null }
                        {tweet.youtubeLink != null ? (
                        <Link
                          to={tweet.youtubeLink} > <img className={tweet.youtubeLink == null ? `linkhide` : `linkshow`} src="/images/twitter/youtube-logo.svg" alt="Youtube" />
                        </Link>
                      ) : null }
                      {tweet.linkedinLink != null ? (
                        <Link
                          to={tweet.linkedinLink} > <img className={tweet.linkedinLink == null ? `linkhide` : `linkshow`} src="/images/twitter/linkedin-logo.svg" alt="LinkedIn" />
                        </Link>
                      ) : null }
                      {tweet.instagramLink != null ? (
                        <Link
                          to={tweet.instagramLink} > <img className={tweet.instagramLink == null ? `linkhide` : `linkshow`} src="/images/twitter/instagram-logo.svg" alt="Instagram" />
                        </Link>
                      ) : null }
                      {tweet.facebookLink != null ? (
                        <Link
                          to={tweet.facebookLink} > <img className={tweet.facebookLink == null ? `linkhide` : `linkshow`} src="/images/twitter/facebook-logo.svg" alt="Facebook" />
                        </Link>
                      ) : null }
                        </div>
                      </div>
                      <div
                        data-aos="fade-up"
                        data-aos-duration="400"
                        data-aos-easing="ease-in-sine"
                        data-aos-delay="200"
                        className="twitter-desc"
                      >
                        {tweet?.twitterText}
                      </div>
                    </div>
                    <div className="black-border-top" />
                    <div className="white-border-top" />
                    <div className="white-border-right" />
                    <div className="white-border-left" />
                  </SwiperSlide>
                ))}
                
              </Swiper>
            </div>
          </div>
        </section>
      )}
    </>
  )
}
