import React from "react"
import "./spotlights.scss"


import SectionTitle from "../SectionTitle"
import SpotlightSwiper from "../SpotlightSwiper"
import { useStaticQuery, graphql } from "gatsby"
export default function Spotlights() {
  const swiperRef = React.useRef(null)
  const dbdata = useStaticQuery(graphql`{
    stringTranslations {
      title_en:translateString(language: EN, string: "IN THE SPOTLIGHT TITLE") 
      title_ar:translateString(language: AR, string: "IN THE SPOTLIGHT TITLE")
      subtitle_en:translateString(language: EN, string: "IN THE SPOTLIGHTS SUBTITLE")
      subtitle_ar:translateString(language: AR, string: "IN THE SPOTLIGHTS SUBTITLE")
      viewall_en:translateString(language: EN, string: "IN THE SPOTLIGHTS VIEW ALL NEWS")
      viewall_ar:translateString(language: AR, string: "IN THE SPOTLIGHTS VIEW ALL NEWS")
    }
  }
  `);
  return (
    <section className="spotlights">
      <SectionTitle translations={dbdata.stringTranslations}/>
      <SpotlightSwiper />
    </section>
  )
}
