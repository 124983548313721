import React from "react"
import {Link} from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import { useContext } from "react"
import { string_translation } from "../../../utils"
import {useStaticQuery, graphql} from "gatsby"

export default function SectionTitle({translations}) {
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  const {more_en,more_ar} = useStaticQuery(graphql`query {
    
    more_en:wpPage(
      template: {templateName: {eq: "Newsroom"}}
      language: {locale: {eq: "en_US"}}
    ) {
      uri
    }
    more_ar:wpPage(
      template: {templateName: {eq: "Newsroom"}}
      language: {locale: {eq: "ar"}}
    ) {
      uri
    }
  }`)
  return (
    <>
      <div className="section-title">
        <div className="container">
          <div className="small-title">{string_translation(translations,"subtitle", locale)}</div>
          <div className="big-title h2">{string_translation(translations,"title", locale)}</div>
          <div className="viewing">
            <div className="view">
              <div className="view-text h2">
              {(locale==='en')?
              <Link to={more_en.uri} >{string_translation(translations,"viewall", locale)}</Link>
              :
              <Link to={more_ar.uri}>{string_translation(translations,"viewall", locale)}</Link>
              } 
              </div>
              <div className="view-img">
                {" "}
                <img src="/images/spotlights/view-img.svg" alt="View Image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
